import React from 'react';
import MarkdownPage from 'components/page/markdown';

const PrivacyPage = () => {
  const data = require('data/md/privacy.md');

  return (
    <MarkdownPage title="Privacy Policy" data={data} />
  );
}

export default PrivacyPage;
