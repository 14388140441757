import React from 'react';
import css from './style.module.scss';

class Ad extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <div className={css.ad} {...this.props}>
        <ins
          className={`${css['responsivead']} adsbygoogle`}
          style={{display: 'block'}}
          data-ad-client="ca-pub-6591554574176682"
          data-ad-slot="6529137450"
          data-ad-format="auto"
          data-ad-responsive="true"
        >
        </ins>
      </div>
    );
  }
}

export default Ad;
