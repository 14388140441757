import React from 'react';

const Kanji = ({width, className}) => (
  <svg className={className} width={width || '78px'} viewBox="0 0 78 37">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path fill="#8C1610" fillRule="nonzero"
        d="M6.32,1.88 L30.6,1.88 L30.6,4.04 C28.28,5.96 25.76,7.6 23.04,9.04 L34.88,9.04
        L34.88,11.4 C33.04,13.96 30.88,16.28 28.32,18.28 L26.24,16.44 C28,15.16 29.76,13.56
        31.44,11.68 L22.16,11.68 L22.16,16.92 C22.16,19.08 20.96,20.2 18.64,20.2 L14.04,20.2
        L13.44,17.64 C14.96,17.72 16.36,17.8 17.72,17.8 C18.84,17.8 19.44,17.28 19.44,16.28
        L19.44,11.68 L18.28,11.68 C15.56,15.04 10.48,17.92 2.96,20.36 L1.44,17.88
        C8.12,16.04 12.76,14 15.4,11.68 L3.12,11.68 L3.12,9.04 L17.2,9.04 C15.56,8.04
        13.68,7.04 11.52,6 L13.16,4.4 L6.32,4.4 L6.32,1.88 Z M13.4,4.4 C15.96,5.68
        18.16,6.92 20.04,8.16 C22.36,7.04 24.52,5.8 26.52,4.4 L13.4,4.4 Z M1.92,24.04
        L17.6,24.04 L17.6,21.12 L20.56,21.12 L20.56,24.04 L36.2,24.04 L36.2,26.68 L23.48,26.68
        C26.4,29.64 30.88,31.96 36.96,33.64 L35.72,36.24 C28.76,33.8 23.84,30.6 20.96,26.68
        L20.56,26.68 L20.56,37 L17.6,37 L17.6,26.68 L17.2,26.68 C14.6,30.44 9.68,33.68 2.48,36.4
        L0.88,33.84 C7.64,31.72 12.28,29.32 14.76,26.68 L1.92,26.68 L1.92,24.04 Z M70.4,36.64
        L66.44,36.64 L65.84,33.96 C67.12,34.08 68.32,34.16 69.44,34.16 C70.36,34.16 70.84,33.68
        70.84,32.76 L70.84,15.96 L66.08,15.96 L66.08,13.2 L77.16,13.2 L77.16,15.96 L73.64,15.96
        L73.64,33.44 C73.64,35.56 72.56,36.64 70.4,36.64 Z M66.76,3 L76.6,3 L76.6,5.68
        L66.76,5.68 L66.76,3 Z M48.84,0.4 L51.32,1.56 C49.28,5.88 46,9.72 41.44,13.08 L40.6,10.32
        C44.36,7.4 47.12,4.08 48.84,0.4 Z M49.2,10.44 L51.68,11.56 C50.88,13.28 49.92,14.92
        48.8,16.52 L48.8,36.88 L46,36.88 L46,19.92 C44.68,21.32 43.2,22.64 41.56,23.92
        L40.72,21.2 C44.6,18.04 47.44,14.44 49.2,10.44 Z M63.72,2.08 C64.48,3.68 65.16,5.36
        65.72,7.16 L63.72,8.04 C63.08,6.04 62.4,4.28 61.68,2.76 L63.72,2.08 Z M57.12,12.08
        L52.52,12.08 L52.52,9.48 L57.56,9.48 L57.56,0.48 L60.2,0.48 L60.2,9.48 L65.28,9.48
        L65.28,12.08 L60.2,12.08 L60.2,15.96 C62.2,18.48 64.2,21.36 66.28,24.56 L64.68,26.96
        C62.96,23.68 61.48,21 60.2,19 L60.2,36.88 L57.56,36.88 L57.56,19.04 C56.12,24.04
        54.24,28.32 51.88,31.92 L50.28,29.32 C53.48,24.84 55.76,19.12 57.12,12.08 Z">
      </path>
    </g>
  </svg>
);

export default Kanji;
