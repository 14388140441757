import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { SITE_TITLE, SITE_DESCRIPTION, SITE_AUTHOR } from 'app/constants';

const SEO = ({description, lang, meta, keywords, title}) => {
  const metaTitle = title || SITE_TITLE;
  const metaDescription = description || SITE_DESCRIPTION;
  const addKeywords = (keywords) => {
    return keywords.length > 0 ? {name: 'keywords', content: keywords.join(', ')} : [];
  };

  return (
    <Helmet
      title={title || ''}
      defaultTitle={SITE_TITLE}
      titleTemplate={`${SITE_TITLE} %s`}
      htmlAttributes={{lang}}
      meta={[
        {name: 'description', content: metaDescription},
        {property: 'og:title', content: metaTitle},
        {property: 'og:description', content: metaDescription},
        {property: 'og:type', content: 'website'},
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:creator', content: SITE_AUTHOR},
        {name: 'twitter:title', content: metaTitle},
        {name: 'twitter:description', content: metaDescription}
      ]
      .concat(addKeywords(keywords))
      .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
};

export default SEO;
