import React from 'react';
import { SITE_EMAIL } from 'app/constants';
import { LinkButton } from 'components/button';
import MessagePage from 'components/page/message';

const HEADING = 'Not Found';
const SUBHEADING = 'This page does not seem to exist';

const ErrorPage = ({heading, subheading, error}) => {
  const head = heading || HEADING;
  const sub = subheading || SUBHEADING;

  return (
    <MessagePage heading={head} subheading={sub}>
      {
        error && error.message ?
          <>
            <pre style={{textAlign: 'left'}}>
              <code>{error.message}</code>
            </pre>
            <p>
              {'Errors can be reported to the '}
              <a href={`mailto:${SITE_EMAIL}?subject=${head}&body=${error.message}`}>
                site administrator
              </a>.
            </p>
          </>
          :
          <LinkButton to="/">Return Home</LinkButton>
      }
    </MessagePage>
  )
};

export default ErrorPage;
