import React from 'react';
import MarkdownPage from 'components/page/markdown';

const TermsPage = () => {
  const data = require('data/md/terms.md');

  return (
    <MarkdownPage title="Terms of Service" data={data} />
  );
}

export default TermsPage;