import React from 'react';
import Ad from 'components/ad';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Logo from 'components/svg/logo';
import Twitter from 'components/svg/twitter';
import { TWITTER_URL } from 'app/constants';
import { SITE_EMAIL, SITE_TITLE } from 'app/constants';
import { Helmet } from 'react-helmet';
import css from './style.module.scss';

const SplashPage = (props) => {
  return (
    <main>
      <section>
        <div className="container center">
          <Helmet>
            <html className="splash" />
            <body className="splash" />
          </Helmet>
          <SEO keywords={[]} />
          <div className="title-lockup">
            <Logo width="300px" />
            <h1 className={css['title']}>{SITE_TITLE}</h1>
            <div className={css['subtitle']}>Coming Soon</div>
          </div>
          <div className={css['link-lockup']}>
            <a href={TWITTER_URL}>
              <Twitter />
              <span>Follow <span>on Twitter</span></span>
            </a>
            <Ad style={{border: 'none'}} />
          </div>
        </div>
      </section>
      <Footer email={SITE_EMAIL} />
    </main>
  );
}

export default SplashPage;
