import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/header';
import Footer from 'components/footer';
import * as classNames from 'classnames';
import { SITE_TITLE, SITE_EMAIL } from 'app/constants';
import 'theme/index.scss';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-322470-12');
ReactGA.pageview(window.location.pathname + window.location.search);

const Layout = ({textAlign, children}) => {
  const classes = classNames({
    'container': true,
    [textAlign]: textAlign === 'center' || textAlign === 'right'
  });

  return (
    <main>
      <Header siteTitle={SITE_TITLE} />
      <section>
        <div className={classes}>{children}</div>
      </section>
      <Footer email={SITE_EMAIL} />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
