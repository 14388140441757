import React from 'react';

const Twitter = ({width, className}) => (
  <svg className={className} width={width || '57px'} height="51px" viewBox="0 0 57 51">
      <path
        fill="#CECFCD"
        d="M51.1410006,12.4640208 C51.1771823,12.9671217 51.1771823,13.4703333
        51.1771823,13.9734343 C51.1771823,29.3186778 39.422779,47 17.9391502,47
        C11.3204446,47 5.17199091,45.0952296 0,41.7891217 C0.940392345,41.8968658
        1.84449158,41.9328174 2.82106571,41.9328174 C8.28228821,41.9328174 13.3096633,40.1000608
        17.3242825,36.9734896 C12.1884734,36.8656349 7.88451127,33.5234647 6.40161485,28.9234315
        C7.12502783,29.0311757 7.84832949,29.1030789 8.60792425,29.1030789 C9.65675061,29.1030789
        10.7056883,28.9592725 11.6821511,28.7078326 C6.32936261,27.6296169 2.31463209,22.9577912
        2.31463209,17.3156044 L2.31463209,17.1719087 C3.86978074,18.0344149 5.6783132,18.5734675
        7.59505701,18.64526 C4.4484666,16.5608423 2.38699565,13.0030733 2.38699565,8.97804428
        C2.38699565,6.82183404 2.96557018,4.8452711 3.97832609,3.12025866 C9.72900285,10.1640042
        18.3731089,14.7639267 28.0659299,15.2671383 C27.8851324,14.4046321 27.776587,13.5062849
        27.776587,12.6078271 C27.776587,6.2108783 32.9847597,1 39.4587381,1 C42.8223079,1
        45.860353,2.40155878 47.9942988,4.66562379 C50.6344556,4.16252282 53.1661784,3.19216183
        55.4086696,1.86250622 C54.5405295,4.55787965 52.6960379,6.82194466 50.2728604,8.25934441
        C52.6237856,8.00790454 54.9023473,7.3608866 57,6.46253941 C55.4088922,8.76244538
        53.4195622,10.8108009 51.1410006,12.4640208 Z">
      </path>
  </svg>
);

export default Twitter;
