import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SplashPage from 'pages/splash';
import PrivacyPage from 'pages/privacy';
import TermsPage from 'pages/terms';
import ErrorPage from 'pages/error';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/" exact={true} component={SplashPage} />
      <Route path="/privacy" exact={true} component={PrivacyPage} />
      <Route path="/terms" exact={true} component={TermsPage} />
      <Route component={ErrorPage} />
    </Switch>
  </Router>
);

export default Routes;
