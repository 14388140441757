import React from 'react';
import css from './style.module.scss';
import Kanji from 'components/svg/kanji';
import { Link } from 'react-router-dom';

const Footer = ({email}) => (
  <footer className={css.footer}>
    <Kanji className={css.kanji} />
    <div className={css.links}>
      <Link to="/terms">Terms</Link>
      <a href={`mailto:${email}`}>Contact</a>
      <Link to="/privacy">Privacy</Link>
    </div>
    <span className={css.copy}>
      &copy; {new Date().getFullYear()} jujitsu.com All Rights Reserved.
    </span>
  </footer>
);

export default Footer;
