import React from 'react';
import css from './style.module.scss';
import { Link } from 'react-router-dom';
import * as classNames from 'classnames';

const Button = ({children, onClick, className, ...rest}) => (
  <button
    onClick={onClick}
    className={classNames({[css.button]: true, [className]: true})}
    {...rest}>
    {children}
  </button>
);

const LinkButton = ({children, className, to, ...rest}) => (
  <Link
    to={to}
    className={classNames({[css.button]: true, [className]: true})}
    {...rest}>
    {children}
  </Link>
);

const LoadingButton = ({label, ...rest}) => (
  <Button {...rest}>{label}</Button>
);

export default Button;
export {
  LinkButton,
  LoadingButton
};
