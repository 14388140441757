export const SITE_TITLE = 'Jujitsu';
export const SITE_DESCRIPTION = 'A collection of Jujitsu from around the web.';
export const SITE_AUTHOR = '@jujitsucom';
export const SITE_EMAIL = 'contact@jujitsu.com';

export const GRAPHQL_URL = 'https://jujitsucom.herokuapp.com/newspaper/graphql/';
export const TWITTER_URL = 'https://twitter.com/jujitsucom';

export const ARTICLE_FEED_LENGTH = 5;
export const ARTICLE_SUMMARY_CHAR_LIMIT = 500;
export const ARTICLE_DATE_MASK = 'LL';
