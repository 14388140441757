import React from 'react';
import ReactDOM from 'react-dom';
import Routes from 'app/routing';
import { ApolloProvider } from 'react-apollo';
import { client } from 'data/apollo';

ReactDOM.render(
  <ApolloProvider client={client}><Routes /></ApolloProvider>,
  document.getElementById('root')
);
