import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/svg/logo';
import css from './style.module.scss';
import { Link } from 'react-router-dom';

const Header = ({siteTitle}) => (
  <header className={css.header}>
    <div className={css.container}>
      <Link to="/">
        <Logo width="90" />
        <h1>{siteTitle}</h1>
      </Link>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
}

export default Header;
