import React from 'react';

const Logo = ({width, className}) => (
  <svg className={className} width={width || '270px'} viewBox="0 0 270 180">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        stroke="#CECFCD" strokeWidth="2"
        fill="#CECFCD"
        points="134.5 2 267 178.666667 2 178.666667">
      </polygon>
      <polygon
        fill="#8C1610"
        points="137.572037 72.7648149
        186.911111 138.553519 152.421852 138.553519 176.615371 170.814815
        178.823704 173.75926 257.185186 173.75926 230.783334 138.553519
        159.498334 43.5166667 138.425926 71.6262964">
      </polygon>
      <polygon
        fill="#1F0504"
        points="134.5 10.1757408 112.563889 39.423889 134.5 68.681852 156.436111 39.423889">
      </polygon>
      <polygon
        fill="#8C1610"
        points="172.689445 173.75926 117.284815 99.8929631 95.3487039 129.141111
        102.415371 138.553519 38.2166667 138.553519 11.8148148 173.75926">
      </polygon>
      <polygon
        fill="#1F0504"
        points="92.2864817 66.5520372 70.3503705 95.8001854 92.2864817
        125.048334 114.222593 95.8001854">
      </polygon>
      <path
        stroke="#CECFCD" fill="#CECFCD" fillRule="nonzero"
        d="M134.5,2 L106.42963,39.423889 L177.096297,133.646111 L148.741297,133.646111
        L92.2962965,58.3762964 L64.2161112,95.8001854 L92.6005557,133.646111 L35.762963,133.646111
        L2,178.666667 L267,178.666667 L134.5,2 Z M11.8148148,173.75926 L38.2166667,138.553519
        L102.415371,138.553519 L95.3487039,129.141111 L117.284815,99.8929631 L172.689445,173.75926
        L11.8148148,173.75926 Z M111.150556,91.7172224 L112.691482,93.7587039
        L114.222593,95.8001854 L92.2962965,125.048334 L90.7553705,123.006852
        L89.2242594,120.965371 L70.3503705,95.8001854 L92.2962965,66.5520372
        L111.150556,91.7172224 Z M186.911111,138.553519 L137.562222,72.7942594
        L159.508148,43.5461112 L257.185186,173.75926 L178.823704,173.75926 L152.421852,138.553519
        L186.911111,138.553519 Z M153.373889,35.3703704 L154.905,37.4118519 L156.436111,39.4533334
        L134.5,68.6720372 L132.968889,66.6305557 L131.437778,64.5890742 L112.563889,39.423889
        L134.5,10.1757408 L153.373889,35.3703704 Z">
      </path>
    </g>
</svg>
);

export default Logo;
