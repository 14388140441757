import React from 'react';
import Layout from 'components/layout';
import Titles from 'components/titles';
import SEO from 'components/seo';

const MessagePage = ({heading, subheading, keywords, children}) => (
  <Layout textAlign="center">
    <SEO title={heading} keywords={keywords || []} />
    <Titles heading={heading} subheading={subheading} />
    <hr className="line" />
    <div className="content">{children}</div>
  </Layout>
);

export default MessagePage;
