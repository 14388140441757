import React from 'react';
import remark from 'remark';
import remark2react from 'remark-react';
import { SITE_TITLE } from 'app/constants';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Titles from 'components/titles';

class MarkdownPage extends React.Component {
  state = {
    markdown: null
  };

  componentDidMount() {
    fetch(this.props.data).then(response => response.text()).then(text => {
      this.setState({
        markdown: remark().use(remark2react).processSync(text).contents
      })
    });
  }

  render() {
    const title = this.props.title || SITE_TITLE;

    return (
      <Layout>
        <SEO title={title} keywords={this.props.keywords || []} />
        <Titles heading={title} />
        <hr className="line" />
        <div className="content">{this.state.markdown}</div>
      </Layout>
    );
  }
};

export default MarkdownPage;
