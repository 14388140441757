import React from 'react';
import PropTypes from 'prop-types';
import css from './style.module.scss';

const Titles = ({heading, subheading}) => (
  <div className={css.titles}>
    <h2>{heading}</h2>
    <h3>{subheading}</h3>
  </div>
);

Titles.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string
};

export default Titles;
